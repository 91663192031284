export default {
    "title": "🇫🇷 Français",

    "首页": "Voyage de réserve",
    "关于": "À propos",
    "会员": "Adhésion",
    "推荐计划": "Programme de parrainage",
    "个人": "Profil",
    "推荐": "Référence",
    "预订": "Réserve",
    "事件": "Événement",
    "登出": "Se déconnecter",
    "登录": "Se connecter",
    "注册": "S'inscrire",
    "确认": "Confirmer",
    "我们的应用程序现在可用": "Restez à l'écoute!",
    "为了获得更好的体验，我们刚刚推出了移动应用程序，以实现无缝、更快的预订。": "Pour une meilleure expérience, nous venons de lancer nos applications mobiles pour une réservation fluide et plus rapide.",
    "iOS版下载（即将推出）": "Télécharger pour iOS (à venir)",
    "Android版下载（即将推出）": "Télécharger pour Android (à venir)",
    "为什么选择我们": "Pourquoi nous choisir",
    "我们卓越的服务。": "Nos services exceptionnels.",
    "收入最高的酒店": "Les meilleures attractions des voyageurs !",
    "我们的合作酒店收入最高。": "Le sanctuaire parfait pour notre partenariat !",
    "回报": "Récompense",
    "最具特色的酒店": "Hôtels les plus en vedette",
    "我们平台上最有特色的酒店。": "Hôtel le plus présenté sur notre plateforme.",
    "热门国家和目的地": "Principaux pays et destinations",
    "全球各住宿类型及酒店业务最佳合作。": "Meilleure coopération dans divers types d’hébergement et d’hôtellerie à travers le monde.",
    "酒店": "Hôtels",
    "预订酒店并开始赚取": "Explorez le monde avec nous !",
    "准备好与我们一起探索酒店的世界！": "Préparez-vous à explorer le monde de l'hôtellerie avec nous !",
    "客户评价": "Témoignages",
    "查看我们用户的真实评价。": "Consultez nos témoignages authentiques de nos utilisateurs.",
    "银行卡列表": "Liste des cartes bancaires",
    "银行列表": "Liste des banques",
    "持卡人姓名": "Nom du titulaire",
    "银行卡号": "Compte bancaire",
    "支付密码": "Retirer le mot de passe",
    "提交": "Soumettre",
    "Pix列表": "Liste de pixels",
    "Pix类型": "Type de pixels",
    "Pix账号": "Compte Pix",
    "账号列表": "Liste des comptes",
    "网络": "Réseau",
    "地址": "Adresse",
    "备注": "Remarque",
    "充值金额": "Montant de recharge",
    "支付类型": "Type de paie",
    "支付银行": "Payer la banque",
    "USDT地址": "Adresse du portefeuille",
    "二维码": "QR Code",
    "充值渠道": "Canal de recharge",
    "充值凭证": "Bon de recharge",
    "立即登录": "Connecte-toi maintenant",
    "用户名": "Nom d'utilisateur",
    "密码": "Mot de passe",
    "邀请码": "Code de Parrainage",
    "确认密码": "Confirmez le mot de passe",
    "手机": "Numéro de téléphone",
    "注册2": "S'inscrire",
    "金额": "Montant",
    "新增": "Nouveau",
    "我们的精彩": "Notre Génial",
    "故事": "Histoire",
    "奖项": "Prix",
    "获得": "Gagné",
    "钱包余额": "Solde des actifs",
    "今日奖励": "Les récompenses du jour",
    "总行程": "Réservation totale",
    "访问行程": "Voyages visités",
    "酒店历史": "Historique des réservations",
    "单价": "Prix",
    "数量": "Nombre",
    "总额": "Total",
    "奖励": "Récompenses",
    "上一页": "Précédent",
    "下一页": "Suivant",
    "每套预订量": "Réservations par set",
    "预订奖励": "Récompense de réservation",
    "每日预订1": "Par ensemble de réservation est de",
    "每日预订2": "voyages",
    "手机号码": "Numéro de téléphone",
    "修改密码": "Changer le mot de passe",
    "登录密码": "Connectez-vous Mot de passe",
    "当前密码": "Ancien mot de passe",
    "新密码": "Nouveau mot de passe",
    "确认新密码": "Confirmer le nouveau mot de passe",
    "保存修改": "Mise à jour",
    "充值": "Recharger",
    "我们的": "Notre",
    "复制": "Copie",
    "分享链接": "Lien de partage",
    "搜索酒店": "Réserver un voyage",
    "实际等级": "Niveau réel",
    "升级": "Mise à niveau",
    "已完成预订": "Réservation terminée",
    "登录时间": "Heure de connexion",
    "信誉分": "Crédibilité",
    "控制菜单": "Menu du tableau de bord",
    "提现": "Retirer",
    "提现账号": "Ajouter des informations de retrait",
    "你确定要删除此账号吗": "Êtes-vous sûr de supprimer ce compte",
    "你确定要删除此地址吗": "Êtes-vous sûr de supprimer cette adresse",
    "提示": "Conseils",
    "是": "Oui",
    "否": "Non",
    "全部": "Tous",
    "待处理": "En attente",
    "冻结中": "Gelé",
    "已完成": "Complété",
    "订阅": "S'abonner",
    "希望在我们推出新模板或更新时收到通知。 只需注册，我们就会通过电子邮件向您发送通知。": "Vous souhaitez être averti lorsque nous lançons un nouveau modèle ou une mise à jour. Inscrivez-vous simplement et nous vous enverrons une notification par e-mail.",
    "我们的伙伴": "Nos partenaires",
    "关于我们": "À PROPOS DE NOUS",
    "实时聊天支持": "Service client en ligne",
    "在线聊天": "Chat en ligne",
    "创建时间": "Temps de création",
    "你确定要登出当前账号吗？": "Êtes-vous sûr de vouloir vous déconnecter du compte actuel ?",
    "请输入你的邮箱": "Entrer votre Email",
    "好评": "Très bien",
    "条评论": "Commentaires",
    "平均每晚": "Moy./Nuit",
    "旅行旅程": "Recommandation de voyage",
    "正在寻找酒店": "À la recherche d'un voyage",
    "开始预订": "Démarrer la réserve",
    "旅程价格": "Tarifs des voyages",
    "佣金": "Commissions",
    "底部-about": "est une plateforme de programme d'affiliation qui vous offre l'opportunité unique de connecter votre entreprise à la plateforme de réservation d'hébergement en ligne la plus reconnue au monde, permettant aux utilisateurs de gagner une récompense avantageuse pour chaque réservation effectuée via celle-ci.",
    "立即预订": "Réservez maintenant",
    "搜索": "Recherche",
    "快乐的客户": "Client heureux",
    "快乐的酒店老板": "Propriétaire de l'hôtel heureux",
    "评论1": "Plateforme incroyable avec d'excellentes fonctionnalités, qui vaut vraiment la peine d'être explorée !",
    "评论2": "Service client proactif qui vous guidera si vous n’êtes pas clair sur la plateforme.",
    "评论3": "Plateforme de confiance, ça vaut le coup d'essayer !",
    "评论4": "Parvenez à répertorier mon hôtel ici et obtenez d'excellents résultats marketing.",
    "推荐计划1": "Les utilisateurs de la plateforme peuvent inviter d'autres personnes à devenir des agents de la plateforme via des codes de parrainage et à devenir votre downline.",
    "推荐计划2": "En tant qu'upline, vous pouvez extraire un certain pourcentage des bénéfices de votre downline, et le profit obtenu par l'upline sera directement reversé sur le compte de la plateforme de l'upline, ou sur le rapport de l'équipe.",
    "推荐计划3": "Remarque : Tous les agents/downlines de la plateforme recevront en conséquence un certain pourcentage des bénéfices et des récompenses de la plateforme. Le développement d'une équipe n'affecte pas les bénéfices et les récompenses des agents ou des downlines.",
    "评分": "Notation",
    "评论": "Commentaire",
    "我觉得选择这次旅行是一个非常明智的决定！": "Je pense que c'était une très sage décision de choisir ce voyage !",
    "第一次体验很棒，下次还会选择这里！": "Super première expérience, je choisirais encore cet endroit la prochaine fois !",
    "你们非常配合，让我很舒服很开心！": "Vous avez été très coopératifs, ce qui me rend si à l'aise et heureux !",
    "感谢您为我带来了一次美妙的旅程！": "Merci d'avoir fait un voyage fantastique pour moi!",
    "服务很好，对我很有耐心！": "Excellent service, très patient avec moi !",
    "你还没有设置支付密码，请前往设置": "Vous n'avez pas défini de mot de passe de paiement, veuillez accéder aux paramètres",
    "订单": "Ordres",
    "确认登录密码": "Confirmer le mot de passe de connexion",
    "我已阅读并同意": "J'ai lu et accepté",
    "注册协议": "Accord d'enregistrement",
    "条约与条款": "termes et conditions",
    "客服": "Support client en ligne",
    "地址列表": "Liste d'adresses",
    "国际旅程推荐": "Recommandation pour les voyages internationaux",
    "您已成功订阅，您将在邮件中收到我们的最新动态！": "Vous vous êtes abonné avec succès, vous recevrez nos dernières nouvelles par Email !",
    "请输入原提现密码": "Veuillez saisir le mot de passe de retrait d'origine",
    "请输入新提现密码": "Veuillez saisir le nouveau mot de passe de retrait",
    "确认提现密码与新提现密码不相同": "Confirmez que le mot de passe de retrait est différent du nouveau mot de passe de retrait",
    "请输入原登录密码": "Veuillez saisir le mot de passe de connexion d'origine",
    "请输入新登录密码": "Veuillez entrer un nouveau mot de passe de connexion",
    "确认登录密码与新登录密码不相同": "Confirmez que le mot de passe de connexion n'est pas le même que le nouveau mot de passe de connexion",
    "请输入提现密码": "Veuillez saisir le mot de passe de retrait",
    "知道了": "Sûr",
    "冻结金额": "Montant gelé",
    '这是一个适合散步的好地方，狭窄的街道，建筑物上有一些可爱的建筑。':"C'est un endroit idéal pour se promener, avec des rues étroites et de charmants bâtiments sur les bâtiments.",
    '海滩和中央大道之间的美丽广场。':"Belle place entre la plage et l'avenue centrale.",
    '非常漂亮的建筑，历史很有趣。':'Très beau bâtiment avec une histoire intéressante.',
    '这里有很多餐厅可供选择，氛围非常轻松。':'Il y a beaucoup de restaurants à choisir ici, avec une atmosphère très détendue.',
    '美丽的土地，漂亮的橘园和动物。 周末度假的绝佳去处。 有趣的地方...':"Beau terrain, charmants vergers d'orangers et animaux. Un endroit idéal pour une escapade de week-end. Endroit intéressant...",
    '一个坐下来观看世界过去的好地方。':"Un endroit idéal pour s'asseoir et regarder le monde passer.",
    '高级海滩俱乐部':'Club de plage haut de gamme',
    '这是对在战争中阵亡的人们的一个可爱的纪念。':"C'est un charmant mémorial pour ceux qui sont morts pendant la guerre.",
    '这是一个闲逛的好地方，有很多值得一看的地方......':"C'est un endroit idéal pour se promener, avec de nombreux endroits à voir...",
    '非常适合度假、放松的好地方！':'Un endroit idéal pour des vacances et de la détente !',
    '第一次来，很温馨，下次还会再来的。':'Première fois ici, très confortable, reviendra la prochaine fois.',
    '这是一种不同的体验，感觉很棒':"C'est une expérience différente et ça se sent bien",
    '当地人都很热情，很有礼貌！':'Les habitants sont très sympathiques et polis !',
    "升级会员请联系客服": "Veuillez contacter le service client pour mettre à niveau votre adhésion",
}